import React from 'react';
import { Provider } from 'react-redux';
import store  from './redux/store';
import GlobalStyles from './assets/styles/globalStyle';
import Routes from './router/routes';
import AppProvider from './AppProvider'; 
import IntlWrapper from './intl/IntlWrapper';

 const App = () => (
    <Provider store={store}>
      <AppProvider>
        <>
          <GlobalStyles />
          <IntlWrapper>
            <Routes /> 
          </IntlWrapper>
        </>
      </AppProvider>
    </Provider>
);

export default App;
