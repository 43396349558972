import create from '@ant-design/icons/lib/components/IconFont';
import React,{useState,createContext} from 'react'; 
import { IntlProvider} from 'react-intl';
import { connect } from 'react-redux';
import mapStateToProps from '../redux/mapStateToProps';
import English from './languages/en-US.json';
import Turkish from './languages/tr-TR.json';


export const Context = createContext();

const local = navigator.language;
let lang ;

if ( local ==='tr-TR') {
  lang = Turkish
} else { 
  lang = English
}



const IntlWrapper = (props) =>{

    const [locale,setLocale] = useState(local);
    const [messages,setMessages] = useState(lang)

     const selectLang = (e) =>{
            const newLocale = e;
            setLocale(newLocale);

        switch(newLocale) {
            case 'tr-TR':
                setMessages(Turkish)
                break;
            case 'en-US':
                setMessages(English)
                break;
            default : 
                setMessages(Turkish)
                break;
        }
    }


    return (
            <Context.Provider  value={{locale, selectLang}}>
                <IntlProvider locale={local} messages={messages}>
                    {props.children}
                </IntlProvider>
            </Context.Provider>
    )

}


export default connect(mapStateToProps)(IntlWrapper);