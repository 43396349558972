import actions from './actions';

const initialState = { 
    cart : [],
} 

export default function ordercart  (state = initialState,action)  { 
    const cart = state.cart
    switch(action.type) {
          
        case actions.ADD_ITEM:  
        cart.push(action.item)
         return  {...state, cart:cart}
         case actions.REMOVE_ITEM: 
            cart.splice(action.index,1);
         return  {...state, cart:cart}
         case actions.EMPTY_CART:  
         return  {...state, cart:[]}
         default:
               return state
    }
};
