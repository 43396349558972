import React from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import themes from './theme/theme.config';

export default function AppProvider ({ children }) {  

return ( 
        <ThemeProvider theme={themes.defaultTheme}>{children}</ThemeProvider>

    )   
}