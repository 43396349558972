const actions = {
      OPEN_MODAL: 'OPEN_MODAL',
      CLOSE_MODAL: 'CLOSE_MODAL',
      EXPAND_ALL: 'EXPAND_ALL',
      COLLAPSE_ALL: 'COLLAPSE_ALL',

      _Open_Modal : (id,selectedTable,isFirstRow) => ({
            type : actions.OPEN_MODAL,
            id:id,
            isFirstRow:isFirstRow===undefined?false:isFirstRow,
            selectedTable:selectedTable
            }),
      _Close_Modal : () => ({
            type : actions.CLOSE_MODAL,
            }),

      _Expand_All : () => ({
            type : actions.EXPAND_ALL,
            }),  
      _Collapse_All : () => ({
            type : actions.COLLAPSE_ALL,
            }),             
      
    };


export default actions;
 