export const PUBLIC_ROUTE = {
    LANDING: '/',
    SIGN_IN: '/signin',
    SIGN_UP: '/signup',
    FORGET_PASSWORD: '/forgotpassword',
    RESET_PASSWORD: '/resetpassword',
    PAGE_404: '/404',
    PAGE_500: '/500',
    INTERVIEW: '/interview/:uid'
  };
  
  export const PRIVATE_ROUTE = {
    DASHBOARD: '/admin',
  
  };
  