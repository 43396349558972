import {createStore} from 'redux';
import allReducers from './boot-reducers';
import moment from 'moment';
// var jwt = require('jsonwebtoken');
import {SignJWT,jwtVerify} from 'jose';  


export async function sign(payload, secret) {
  
      const iat = Math.floor(Date.now() / 1000);
      const exp = iat + 60* 60*24; 
      return new SignJWT({...payload})
          .setProtectedHeader({alg: 'HS256', typ: 'JWT'})
          .setExpirationTime(exp)
          .setIssuedAt(iat)
          .setNotBefore(iat)
          .sign(new TextEncoder().encode(secret));
    }


async function saveToLocalStorage (state) { 
      const scrt = state.constants.loginToken;
       try {
            const serializedState = JSON.stringify(state);
            const timer =60*30;
            const expt = moment(new Date()).add(timer,'s');
            localStorage.setItem('expires',expt);
            // var tkn_state = jwt.sign({ serializedState }, scrt,{ expiresIn:  timer,algorithm:'HS512'});
            const tkn_state =  await sign(serializedState,scrt);  
            // console.log(tkn_state,tkn_state)
            localStorage.setItem('stt',serializedState)
            localStorage.setItem('mtr',state.constants.loginToken)

       }
      catch (e) { 
            console.log(e);
      }
      
}

async function loadFromLocalStorage(){
      try {
            const serializedState =  localStorage.getItem('stt');
            let scrt  = localStorage.getItem('mtr');
            // console.log('scrt',scrt)
            // console.log('serializedState',serializedState)

            // jwt.verify(serializedState, scrt, function(err, decoded) {

            //       if(err) {localStorage.clear()} 
 
            //    });
            
            // const tkn_state =  jwt.verify(serializedState, scrt)
            // const  tkn_state = await jwtVerify(serializedState , new TextEncoder().encode(scrt));    
            // console.log(tkn_state)
            // console.log(JSON.parse(tkn_state))

            if(serializedState===null)
                  {
                        return undefined
                  }
            else
                  {
                        return JSON.parse(serializedState)
                  }

       }
      catch (e) { 
            console.log(e);
            // localStorage.clear()
            return undefined
      }
}


const persistedState = await loadFromLocalStorage(); 
const store = createStore(allReducers,persistedState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) //

store.subscribe(async ()=> await saveToLocalStorage(store.getState()))

export default store;