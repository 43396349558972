import React, { lazy, Suspense } from 'react';
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
  
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import { PUBLIC_ROUTE } from './router.constants';
import Loader from '../components/utility/loader/loader';

const Dashboard = lazy(() => import('../pages/dashboard/Dashboard'));

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('../pages/signin/SignIn')),
  },
//   ,
//   {
//     path: PUBLIC_ROUTE.PAGE_404,
//     component: lazy(() => import('@iso/containers/Pages/404/404')),
//   },
//   {
//     path: PUBLIC_ROUTE.PAGE_500,
//     component: lazy(() => import('@iso/containers/Pages/500/500')),
//   },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import('../pages/signin/SignIn')),
  },
//   {
//     path: PUBLIC_ROUTE.SIGN_UP,
//     component: lazy(() => import('@iso/containers/Pages/SignUp/SignUp')),
//   },
//   {
//     path: PUBLIC_ROUTE.FORGET_PASSWORD,
//     component: lazy(() =>
//       import('@iso/containers/Pages/ForgotPassword/ForgotPassword')
//     ),
//   },
//   {
//     path: PUBLIC_ROUTE.RESET_PASSWORD,
//     component: lazy(() =>
//       import('@iso/containers/Pages/ResetPassword/ResetPassword')
//     ),
//   }

{
  path: PUBLIC_ROUTE.INTERVIEW,
  exact: true,
  component: lazy(() => import('../pages/interview')),
},
];

function PrivateRoute({ children, ...rest }) {
  const isLoggedIn =  useSelector(state => state.constants.isLoggedIn);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const  Routes = ()=>{
  return (
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact} >
                <route.component />
              </Route>
            ))}
            <PrivateRoute path="/admin">
             <Dashboard /> 
            </PrivateRoute>
          </Switch>
        </Router>
      </Suspense>
  );
}
export default Routes;