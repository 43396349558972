import {combineReducers} from 'redux';
import constants from './config/reducer'; 
import ordercart  from './order/reducer';
import modals  from './modal/reducer';
import App from './app/reducer';
import ThemeSwitcher from './themeswitcher/reducer';

 const AllReducers =   combineReducers({
      App:App,
      constants:constants,
      ordercart:ordercart,
      modals:modals,
      ThemeSwitcher:ThemeSwitcher,
       });

export default AllReducers;

