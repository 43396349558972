import actions from './actions';

const initialState = { 
    modalVisible : false,
    selectedId: '',
    selectedTable:'',
    expandAll:false,
    isFirstRow:false,
} 

export default function modals  (state = initialState,action)  { 
    switch(action.type) {
        case actions.OPEN_MODAL:  
         return  {...state, modalVisible:true,selectedId:action.id,selectedTable:action.selectedTable,isFirstRow:action.isFirstRow}

        case actions.CLOSE_MODAL:  
        return  {...state, modalVisible:false,selectedId:'',selectedTable:''} 
        case actions.EXPAND_ALL:  
        return  {...state, expandAll:true} 
        case actions.COLLAPSE_ALL:  
        return  {...state, expandAll:false} 
         default:
               return state
    }
};
