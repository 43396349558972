const actions = {
      ADD_ITEM: 'ADD_ITEM',
      REMOVE_ITEM: 'REMOVE_ITEM',
      EMPTY_CART: 'EMPTY_CART',



      _Add_Item : (item) => ({
            type : actions.ADD_ITEM,
            item:item
            }),

      _Remove_Item : (index) => ({
            type : actions.REMOVE_ITEM,
            index:index
            }),
      _Empty_Cart : () => ({
            type : actions.EMPTY_CART,
            }),
     
    };

export default actions;
 